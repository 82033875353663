import Client from 'shopify-buy';

// Initializing a client
const ShopifyClient = Client.buildClient({
  domain: process.env.SHOPIFY_STOREFRONT_DOMAIN || 'shop.dogio.fi',
  storefrontAccessToken:
    process.env.SHOPIFY_STOREFRONT_TOKEN || '189fe07af577b28bcb4d6c09adf57c1a',
  apiVersion: '2024-04',
})

export default ShopifyClient
