import { ReactNode, useState } from 'react'
import { SlideOverContextInterface } from './slideOverContainer'

export const useSlideOver = (): SlideOverContextInterface => {
  const [slideOver, setSlideOver] = useState(false)
  const [slideOverContent, setSlideOverContent] = useState<ReactNode>('')

  const handleSlideOver = (content = false) => {
    setSlideOver(!slideOver)
    if (content) {
      setSlideOverContent(content)
    }
  }

  return { slideOver, handleSlideOver, slideOverContent }
}
