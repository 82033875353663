import { createContext, ReactElement } from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export interface CartItem {
  readonly id?: string
  qty: number
  sku: string
  title: string
  variantTitle?: string
  image: IGatsbyImageData
  price: number
  shopifyStoreFrontId: string
}

export interface CartContextInterface {
  loading: boolean
  items: CartItem[]
  addItem: (item: CartItem) => void
  editItem: (item: CartItem) => void
  removeItem: (item: CartItem) => void
  clear: () => void
  order: () => Promise<{ redirectUrl: string }>
}

export const CartContextDefaultValue: CartContextInterface = {
  loading: false,
  items: [],
  addItem: () => {
    throw new Error('No addMessage defined')
  },
  editItem: () => {
    throw new Error('No editItem defined')
  },
  removeItem: () => {
    throw new Error('No removeMessage defined')
  },
  clear: () => {
    throw new Error('No clear defined')
  },
  order: () => {
    throw new Error('No clear defined')
  },
}
export const CartContext = createContext<CartContextInterface>(
  CartContextDefaultValue
)
