import { createContext, ReactElement } from 'react'

export interface AppMessage {
  readonly id?: string
  body: string | ReactElement
  type: 'success' | 'warning' | 'danger'
}

export interface MessageContextInterface {
  messages: AppMessage[]
  addMessage: (message: AppMessage) => void
  removeMessage: (messageId) => void
  clearMessages: () => void
}

export const MessageContextDefaultValue: MessageContextInterface = {
  messages: [],
  addMessage: () => {
    throw new Error('No addMessage defined')
  },
  removeMessage: () => {
    throw new Error('No removeMessage defined')
  },
  clearMessages: () => {
    throw new Error('No clearMessages defined')
  },
}
export const MessageContext = createContext<MessageContextInterface>(
  MessageContextDefaultValue
)
