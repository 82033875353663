import { CartContextInterface, CartItem } from './cartContext'
import { useContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import useSessionStorage from 'use-session-storage-state'
import { AuthContext } from '../../../components/auth/authContext'
import ShopifyClient from '../../../lib/shopify'
import { MessageContext } from '../../messages/context/messageContext'

export const useCartContext = (): CartContextInterface => {
  const [items, setItems] = useSessionStorage<CartItem[]>('cartItems', {
    defaultValue: [],
  })
  const [loading, setLoading] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const { addMessage } = useContext(MessageContext)

  const addItem = (p: CartItem) => {
    const existingItem = items.find((item) => item.sku === p.sku)
    const newItem = {
      ...p,
      id: uuid(),
      qty: existingItem ? existingItem.qty + p.qty : p.qty,
    }
    if (existingItem) {
      setItems([
        ...items.filter((item) => item.id !== existingItem.id),
        { ...newItem, id: existingItem.id },
      ])
    } else {
      setItems([...items, newItem])
    }
    addMessage({ body: 'Tuote lisätty koriin', type: 'success' })
  }
  const editItem = (p: CartItem) => {
    const { id } = p
    const existingItem = items.find((item) => item.id === id)
    if (existingItem) {
      setItems([...items.filter((item) => item.id !== existingItem.id), p])
    } else {
      addItem(p)
    }
  }
  const removeItem = (p: CartItem) => {
    const { id } = p
    setItems([...items.filter((item) => item.id !== id)])
  }
  const clear = () => {
    setItems([])
  }

  const order = async (): Promise<{ redirectUrl: string }> => {
    setLoading(true)
    const email = null !== currentUser && currentUser?.email
    const input = {
      lineItems: items.map((item) => ({
        variantId: item.shopifyStoreFrontId,
        quantity: item.qty,
      })),
      email: email || undefined,
    }

    const { webUrl: redirectUrl } = await ShopifyClient.checkout.create(input)
    setLoading(false)
    return { redirectUrl }
  }
  return {
    addItem,
    editItem,
    removeItem,
    clear,
    items,
    order,
    loading,
  }
}
