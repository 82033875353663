import React, { FunctionComponent } from 'react'
import { CartContext } from './cartContext'
import { useCartContext } from './useCartContext'

const { Provider } = CartContext

const CartContextProvider: FunctionComponent<any> = ({ children }) => {
  const { addItem, editItem, removeItem, clear, items, order, loading } =
    useCartContext()

  return (
    <Provider
      value={{
        addItem,
        editItem,
        removeItem,
        clear,
        items,
        order,
        loading,
      }}
    >
      {children}
    </Provider>
  )
}

export { CartContext, CartContextProvider }
