import React, { FunctionComponent } from 'react'
import { MessageContext } from './messageContext'
import { useMessageContext } from './useMessageContext'

const { Provider } = MessageContext

const MessageContextProvider: FunctionComponent<any> = ({ children }) => {
  const { addMessage, removeMessage, clearMessages, messages } =
    useMessageContext()

  return (
    <Provider
      value={{
        addMessage,
        removeMessage,
        clearMessages,
        messages,
      }}
    >
      {children}
    </Provider>
  )
}

export { MessageContext, MessageContextProvider }
