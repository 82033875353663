import React, { createContext, ReactNode } from 'react'
import { useSlideOver } from './useSliderOver'
import { SlideOver } from './slideOver'

export interface SlideOverContextInterface {
  slideOverContent: ReactNode
  slideOver: boolean
  handleSlideOver: (p: any) => void
}
export const SlideOverContextDefaultValue = {
  slideOverContent: '',
  slideOver: false,
  handleSlideOver: (p) => {
    throw new Error('No handleSlideOver defined')
  },
}
const SlideOverContext = createContext<SlideOverContextInterface>(
  SlideOverContextDefaultValue
)
const { Provider } = SlideOverContext

const SlideOverProvider = ({ children }) => {
  const { slideOver, handleSlideOver, slideOverContent } = useSlideOver()
  return (
    <Provider value={{ slideOver, handleSlideOver, slideOverContent }}>
      <SlideOver />
      {children}
    </Provider>
  )
}

export { SlideOverContext, SlideOverProvider }
