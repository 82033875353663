import React, { FunctionComponent, useContext } from 'react'
import { Transition } from '@headlessui/react'
import { SlideOverContext } from './slideOverContainer'

export const SlideOver: FunctionComponent<any> = () => {
  const { slideOverContent, handleSlideOver, slideOver } =
    useContext(SlideOverContext)

  const closeSlideOver = () => {
    handleSlideOver('')
  }

  return (
    <>
      <Transition
        as="div"
        show={slideOver}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40"
        onClick={() => closeSlideOver()}
      />
      <Transition
        as="div"
        show={slideOver}
        enter="transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="fixed inset-y-0 right-0 pl-10 max-w-full flex z-50"
      >
        {slideOverContent}
      </Transition>
    </>
  )
}
