import { createContext, Dispatch, SetStateAction } from 'react'
import {AuthUser} from "aws-amplify/auth";

export type AuthMode =
  | 'signIn'
  | 'signUp'
  | 'confirmSignUp'
  | 'loggedIn'
  | 'forgotPassword'
  | 'confirmForgotPassword'
  | 'newPassword'

export type SignInProps = {
  email: string
  password: string
}

export type SignOutProps = {
  global?: boolean
}

export type SignUpProps = {
  email: string
  password: string
}

export type ConfirmSignUpProps = {
  email: string
  code: string
  password?: string
}

export type ConfirmUser = {
  user: string
  codeDeliveryDetails: any
  password: string
} | null

export type ConfirmUserForgotPassword = {
  user: string
} | null

export type ForgotPasswordProps = {
  email: string
}

export type ConfirmForgotPasswordProps = {
  email: string
  code: string
  password: string
}

export type ResendConfirmationProps = {
  username: string
}

export type NewPasswordUser = {
  email: string
  missingAttributes: any
} | null

export type NewPasswordConfirmationProps = {
  password: string
}

export interface User {
  email: string
  idToken: any
  authUser: AuthUser
  groups: string[]
}

export interface AuthContextInterface {
  authMode: AuthMode
  isLoggedIn: boolean
  isLoading: boolean
  isAdmin: boolean
  isTrainer: boolean
  currentUser: User|null
  confirmUser: ConfirmUser|null
  confirmUserForgotPassword: ConfirmUserForgotPassword|null
  setAuthMode: Dispatch<SetStateAction<AuthMode>>
  signIn: (p: SignInProps) => Promise<void>
  signUp: (p: SignUpProps) => Promise<void>
  confirmSignUp: (p: ConfirmSignUpProps) => Promise<void>
  signOut: (p?: SignOutProps) => Promise<void>
  forgotPassword: (p: ForgotPasswordProps) => Promise<void>
  confirmForgotPassword: (p: ConfirmForgotPasswordProps) => Promise<void>
  resendConfirmation: (p: ResendConfirmationProps) => Promise<void>
  newPassword: (p: NewPasswordConfirmationProps) => Promise<void>
  newPasswordUser: NewPasswordUser
}

export const AuthContextDefaultValue: AuthContextInterface = {
  authMode: 'signIn',
  isLoggedIn: false,
  isAdmin: false,
  isTrainer: false,
  currentUser: null,
  confirmUser: null,
  confirmUserForgotPassword: null,
  newPasswordUser: null,
  isLoading: false,
  signIn: (p) => Promise.reject('No signIn Defined'),
  signUp: (p) => Promise.reject('No signUp Defined'),
  signOut: (p) => Promise.reject('No signOut Defined'),
  setAuthMode: (p) => {
    throw new Error('No setAuthMode Defined')
  },
  confirmSignUp: (p) => Promise.reject('No confirmSignUp Defined'),
  forgotPassword: (p) => Promise.reject('No forgotPassword Defined'),
  confirmForgotPassword: (p) => Promise.reject('No confirmForgotPassword Defined'),
  resendConfirmation: (p) => Promise.reject('No resendConfirmation Defined'),
  newPassword: (p) => Promise.reject('No newPassword Defined'),
}

export const AuthContext = createContext<AuthContextInterface>(
  AuthContextDefaultValue
)
