import { AppMessage, MessageContextInterface } from './messageContext'
import { useState } from 'react'
import {v4 as uuid} from "uuid";

export const useMessageContext = (): MessageContextInterface => {
  const [messages, setMessages] = useState<AppMessage[]>([])

  const addMessage = (p) => {
    const message = {
      id: uuid(),
      ...p
    }
    setMessages([...messages, message])
    setTimeout(() => {
      removeMessage(message)
    }, 5000)
  }
  const removeMessage = (message) => {
    const { id } = message
    setMessages([...messages.filter((message) => message.id !== id)])
  }
  const clearMessages = () => {
    setMessages([])
  }
  return {
    addMessage,
    removeMessage,
    clearMessages,
    messages,
  }
}
