import { ResourcesConfig } from 'aws-amplify'

export const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId:
        process.env.COGNITO_IDENTITY_POOL_ID ||
        'eu-central-1:519f2cb9-8c42-4818-8bcf-c0d1dcc60875',
      userPoolId: process.env.COGNITO_USER_POOL_ID || 'eu-central-1_A0PK9sm9I',
      userPoolClientId:
        process.env.COGNITO_CLIENT_ID || '356sdq2hj04qb6dmloarknqr6j',
      loginWith: {
        oauth: {
          domain: 'auth.dogscent.fi',
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: ['https://dogio.fi/asiakastili/'],
          redirectSignOut: ['https://dogio.fi/'],
          responseType: 'token',
        },
      },
    },
  },
  API: {
    REST: {
      Training: {
        endpoint:
          process.env.TRAINING_SERVICE_ENDPOINT ||
          'https://api.dogio.fi/training',
      },
      Lecture: {
        endpoint:
          process.env.LECTURE_SERVICE_ENDPOINT ||
          'https://api.dogio.fi/lecture',
      },
      Reservation: {
        endpoint:
          process.env.RESERVATION_SERVICE_ENDPOINT ||
          'https://api.dogio.fi/reservation',
      },
      Order: {
        endpoint:
          process.env.ORDER_SERVICE_ENDPOINT || 'https://api.dogio.fi/order',
      },
      Provider: {
        endpoint:
          process.env.PROVIDER_SERVICE_ENDPOINT ||
          'https://api.dogio.fi/provider',
      },
    },
  },
}
