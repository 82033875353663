import React, { FunctionComponent } from 'react'
import { useAuthContext } from './useAuthContext'
import { AuthContext } from './authContext'

const AuthContextProvider: FunctionComponent<any> = ({ children }) => {
  const {
    isLoggedIn,
    authMode,
    signIn,
    signOut,
    signUp,
    setAuthMode,
    confirmSignUp,
    currentUser,
    confirmUser,
    confirmUserForgotPassword,
    confirmForgotPassword,
    forgotPassword,
    resendConfirmation,
    newPasswordUser,
    newPassword,
    isLoading,
    isAdmin,
    isTrainer,
  } = useAuthContext()

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        authMode,
        signIn,
        signOut,
        signUp,
        setAuthMode,
        confirmSignUp,
        currentUser,
        confirmUser,
        confirmUserForgotPassword,
        confirmForgotPassword,
        forgotPassword,
        resendConfirmation,
        newPasswordUser,
        newPassword,
        isLoading,
        isAdmin,
        isTrainer,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthContextProvider }
