import { useEffect, useState } from 'react'
import {
  AuthContextInterface,
  AuthMode,
  ConfirmForgotPasswordProps,
  ConfirmSignUpProps,
  ConfirmUser,
  ConfirmUserForgotPassword,
  ForgotPasswordProps,
  NewPasswordConfirmationProps,
  NewPasswordUser,
  ResendConfirmationProps,
  SignInProps,
  SignUpProps, User,
} from './authContext'
import {Amplify} from 'aws-amplify'
import { Hub } from 'aws-amplify/utils';
import {
  AuthUser,
  fetchAuthSession,
  getCurrentUser,
  signUp as AmplifySignUp,
  signIn as AmplifySignIn,
  confirmSignUp as AmplifyConfirmSignUp,
  confirmSignIn as AmplifyConfirmSignIn,
  resetPassword as AmplifyResetPassword,
  confirmResetPassword as AmplifyConfirmResetPassword,
  signOut as AmplifySignOut,
  autoSignIn,
  resendSignUpCode,
} from 'aws-amplify/auth'
import {awsConfig} from "../../config/aws";
import { navigate } from "gatsby"

Amplify.configure(awsConfig)


const _guessInitialLoginStatus = () => {
  if (typeof window !== 'undefined') {
    let flagValue: string | null = ''
    if (typeof localStorage !== 'undefined') {
      flagValue = localStorage.getItem(`isUserLoggedIn`)
    }

    // If the flag is null, then the user probably isn't logged in (if you add this to your code, the user will experience a one-time-only bad guess). Otherwise, check the flag's value.
    return '' !== flagValue && flagValue === 'true'
  }
  return false
}

const _setFlag = (value: boolean) => {
  const valAsString = value ? 'true' : 'false'
  localStorage.setItem('isUserLoggedIn', valAsString)
}

export const useAuthContext = (): AuthContextInterface => {
  const [authMode, setAuthMode] = useState<AuthMode>('signIn')
  const initialGuess = _guessInitialLoginStatus()
  const [isLoggedIn, setIsLoggedIn] = useState(initialGuess)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [confirmUser, setConfirmUser] = useState<ConfirmUser>(null)
  const [newPasswordUser, setNewPasswordUser] = useState<NewPasswordUser>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isTrainer, setIsTrainer] = useState(false)
  const [confirmUserForgotPassword, setConfirmUserForgotPassword] =
    useState<ConfirmUserForgotPassword>(null)

  const updateUser = async () => {
    setIsLoading(true)
    try {
      const authSession = await fetchAuthSession()
      if (!authSession.tokens) {
        _updateLoggedInState(false)
        removeCurrentUser()
        setIsLoading(false)
        return {redirectToAdmin: false}
      }
      const { tokens: {idToken} } = authSession
      const user = await getCurrentUser()
      const {redirectToAdmin} = _updateCurrentUser(user, idToken)

      _updateLoggedInState(true)
      setIsLoading(false)
      return {redirectToAdmin}
    } catch (e) {
      _updateLoggedInState(false)
      removeCurrentUser()
      setIsLoading(false)
    }
    return {redirectToAdmin: false}
  }

  const _updateLoggedInState = (value: boolean) => {
    setIsLoggedIn(value)
    _setFlag(value)
  }

  const removeCurrentUser = () => {
    setIsAdmin(false)
    setIsTrainer(false)
    setCurrentUser(null)
  }

  const _updateCurrentUser = (user: AuthUser, idToken) => {
    setCurrentUser({
      email: idToken.payload.email,
      groups: idToken?.payload['cognito:groups'],
      idToken,
      authUser: user
    })
    const groups = idToken?.payload['cognito:groups']
    let redirectToAdmin = false
    if (Array.isArray(groups) && groups.includes('dogio-admin')) {
      setIsAdmin(true)
      redirectToAdmin = true
    }
    if (Array.isArray(groups) && groups.includes('dogio-trainer')) {
      setIsTrainer(true)
      redirectToAdmin = true
    }
    return { redirectToAdmin }
  }

  const signIn = async (p: SignInProps) => {
    const { email, password } = p
    try {
      const result = await AmplifySignIn({
        username: email,
        password,
      })
      if (
        result.nextStep.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        const { missingAttributes } = result.nextStep
        setNewPasswordUser({
          email,
          missingAttributes,
        })
        setAuthMode('newPassword')
      }
      const {redirectToAdmin} = await updateUser()
      if (redirectToAdmin) {
        await navigate('/hallinta')
      }
      if (
        typeof window !== 'undefined' &&
        typeof window.dataLayer !== 'undefined'
      ) {
        window.dataLayer.push({
          event: 'login',
        })
      }
    } catch (e: any) {
      if (e.code === 'UserNotConfirmedException') {
        setConfirmUser({
          user: p.email,
          codeDeliveryDetails: {
            attributeName: '',
            deliveryMedium: 'EMAIL',
            destination: p.email,
          },
          password,
        })
        setAuthMode('confirmSignUp')
      } else {
        throw e
      }
    }
  }

  const signOut = async (p) => {
    await AmplifySignOut(p)
    setAuthMode('signIn')
  }

  const signUp = async (p: SignUpProps) => {
    const { email, password } = p
    const { isSignUpComplete, nextStep: codeDeliveryDetails } =
      await AmplifySignUp({
        username: email.toLowerCase(),
        password,
        options: {
          userAttributes: {
            email: email.toLowerCase(),
          },
          autoSignIn: true,
        },
      })
    if (isSignUpComplete) {
      await updateUser()
      setAuthMode('loggedIn')
    } else {
      setConfirmUser({
        user: email,
        codeDeliveryDetails,
        password,
      })
      setAuthMode('confirmSignUp')
    }
  }

  const confirmSignUp = async (p: ConfirmSignUpProps) => {
    const { email, code, password } = p
    const { isSignUpComplete, userId, nextStep } = await AmplifyConfirmSignUp({
      username: email.toLowerCase(),
      confirmationCode: code,
    })
    const { isSignedIn } = await autoSignIn()

    if (isSignedIn) {
      await updateUser()
      setAuthMode('loggedIn')
    } else {
      setAuthMode('signIn')
    }
  }

  const forgotPassword = async (p: ForgotPasswordProps) => {
    const { email } = p
    const result = await AmplifyResetPassword({
      username: email.toLowerCase(),
    })
    setConfirmUserForgotPassword({
      user: email,
    })
    setAuthMode('confirmForgotPassword')
  }

  const resendConfirmation = async (p: ResendConfirmationProps) => {
    const { username } = p
    await resendSignUpCode({ username: username.toLowerCase() })
  }

  const confirmForgotPassword = async (p: ConfirmForgotPasswordProps) => {
    const { email, code, password } = p
    await AmplifyConfirmResetPassword({
      username: email.toLowerCase(),
      confirmationCode: code,
      newPassword: password,
    })
    setAuthMode('signIn')
  }

  const newPassword = async (p: NewPasswordConfirmationProps) => {
    const { password } = p
    const { isSignedIn, nextStep } = await AmplifyConfirmSignIn({
      challengeResponse: password,
      options: {
        userAttributes: newPasswordUser?.missingAttributes,
      },
    })
  }

  useEffect(() => {
    ;(async () => {
      const hubListenerCancel = Hub.listen('auth', updateUser) // listen for login/signup events
      await updateUser()
    })()
  }, [])

  return {
    authMode,
    isLoggedIn,
    setAuthMode,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    currentUser,
    confirmUser,
    confirmUserForgotPassword,
    forgotPassword,
    confirmForgotPassword,
    resendConfirmation,
    newPassword,
    newPasswordUser,
    isLoading,
    isAdmin,
    isTrainer,
  }
}
